const Icons = {
  beginnerGuide: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.617" height="25.056" viewBox="0 0 22.617 25.056">
      <g id="beginner_guide_icon" data-name="beginner guide icon" transform="translate(24038.324 14759.264)">
        <g id="Group_10481" data-name="Group 10481">
          <path id="Path_9095" data-name="Path 9095" d="M-24037.824-14754.984h21.617v20.618h-21.617v-20.618l20.094-3.437v2.824" transform="translate(0 -0.342)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <text id="_" data-name="?" transform="translate(-24028.477 -14753.736)" fill="currentColor" fontSize="14" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="14">?</tspan></text>
          <path id="Path_9096" data-name="Path 9096" d="M-24033.73-14755.258v20.5" transform="translate(0 0.046)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        </g>
      </g>
    </svg>
  ),
  tradingGuide: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.617" height="25.056" viewBox="0 0 22.617 25.056">
      <g id="trading_guide_icon" data-name="trading guide icon" transform="translate(24066.543 14759.265)">
        <g id="Group_10482" data-name="Group 10482" transform="translate(-28.219)">
          <path id="Path_9095" data-name="Path 9095" d="M-24037.824-14754.984h21.617v20.618h-21.617v-20.618l20.094-3.437v2.824" transform="translate(0 -0.342)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_9096" data-name="Path 9096" d="M-24033.73-14755.258v20.5" transform="translate(0 0.046)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
          <text id="_" data-name="$" transform="translate(-24029.699 -14754.006)" fill="currentColor" fontSize="14" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="14">$</tspan></text>
        </g>
      </g>
    </svg>
  ),
  frequentlyAskedQuestions: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25.5" viewBox="0 0 26 25.5">
      <g id="faq_icon" data-name="faq icon" transform="translate(-58.25 -694)">
        <g id="Group_5256" data-name="Group 5256" transform="translate(59 694.5)">
          <path id="BytesizeMessage" d="M2,4H27V21.64H14.5L7.357,28.5V21.64H2Z" transform="translate(-2.25 -4)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </g>
        <text id="_" data-name="?" transform="translate(68 694)" fill="currentColor" fontSize="14" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="14">?</tspan></text>
      </g>
    </svg>
  ),
  announcement: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.986" height="22.484" viewBox="0 0 25.986 22.484">
      <g id="announcement_icon" data-name="announcement icon" transform="translate(0.5 0.5)">
        <path id="Path_9038" data-name="Path 9038" d="M-12766.223-784.576h-7.129V-794.3h7.129l9.515-5.7v21.484l-9.515-6.063" transform="translate(12773.352 799.997)" fill="none" fillRule="nonzero" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_9039" data-name="Path 9039" d="M-12752.035-795.271s4.133.923,4.223,6.036a5.931,5.931,0,0,1-4.223,6.155" transform="translate(12772.798 799.873)" fill="none" fillRule="nonzero" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </svg>
  ),
  stories: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.658" height="25.322" viewBox="0 0 25.658 25.322">
      <g id="news_icon" data-name="news icon" transform="translate(-15.5 -14.643)">
        <path id="Path_2267" data-name="Path 2267" d="M20.5,22.33H31.7" transform="translate(0)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_2268" data-name="Path 2268" d="M20.5,32.67H31.7" transform="translate(0)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_2269" data-name="Path 2269" d="M20.5,27.5H31.7" transform="translate(0)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_9097" data-name="Path 9097" d="M4248.453,9583.465H4228v-24.322h20.453Z" transform="translate(-4212 -9544)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_9098" data-name="Path 9098" d="M4248.453,9562.849h4.205v20.616h-4.205" transform="translate(-4212 -9544)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </svg>
  ),
  newsletter: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g id="dynamic_icon" data-name="dynamic icon" transform="translate(0.5 0.5)">
        <path id="support_icon" data-name="support icon" d="M4.6,3.6h25v18H17.1l-7.144,7v-7H4.6Z" transform="translate(-4.6 -3.6)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <circle id="Ellipse_524" data-name="Ellipse 524" cx="1.569" cy="1.569" r="1.569" transform="translate(5.628 7.866)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <circle id="Ellipse_525" data-name="Ellipse 525" cx="1.569" cy="1.569" r="1.569" transform="translate(10.931 7.866)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <circle id="Ellipse_526" data-name="Ellipse 526" cx="1.569" cy="1.569" r="1.569" transform="translate(16.234 7.866)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </svg>
  ),
  calendar: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.586" height="26.806" viewBox="0 0 26.586 26.806">
      <g id="calendar_icon" data-name="calendar icon" transform="translate(0.5 0.5)">
        <path id="BytesizeClipboard" d="M22.56,7.31H4.65V31.072H30.235V7.31H22.56" transform="translate(-4.65 -5.266)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_9101" data-name="Path 9101" d="M14.44,3.51V7.275" transform="translate(-9.174 -3.51)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_9102" data-name="Path 9102" d="M42.44,3.51V7.275" transform="translate(-22.115 -3.51)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_9104" data-name="Path 9104" d="M10.43,22.68h4v4h-4Z" transform="translate(-7.132 -12.369)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_9105" data-name="Path 9105" d="M38.43,22.68h4v4h-4Z" transform="translate(-20.072 -12.369)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_9106" data-name="Path 9106" d="M24.43,22.68h4v4h-4Z" transform="translate(-13.602 -12.369)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_9103" data-name="Path 9103" d="M4.65,16.65H30.236" transform="translate(-4.65 -9.583)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
      </g>
    </svg>
  ),
  twitter: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.028" height="25.5" viewBox="0 0 26.028 25.5">
      <g id="twitter_icon" data-name="twitter icon" transform="translate(-280.953 -167.06)">
        <path id="path1009" d="M281.561,167.31l9.652,13.789L281.5,192.31h2.186l8.5-9.815,6.871,9.815H306.5l-10.2-14.565,9.041-10.435H303.16l-7.832,9.04L289,167.31Zm3.215,1.72h3.418l15.091,21.559h-3.418Z" fill="currentColor" stroke="#0a0c1a" strokeWidth="0.5" />
      </g>
    </svg>
  ),
  termsOfService: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <path id="terms_of_service_icon" data-name="terms of service icon" d="M24.321,13.607V27H2V4.679H15.393M27,5.571,23.429,2,8.25,17.179,6.464,22.536l5.357-1.786Zm-7.143,0,3.571,3.571ZM8.25,17.179l3.571,3.571Z" transform="translate(-1.5 -1.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </svg>
  ),
  privacyPolicy: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g id="privacy_policy_icon" data-name="privacy policy icon" transform="translate(-61.5 -616.5)">
        <path id="BytesizeClipboard" d="M13.5,2V5.571h10V2ZM12.25,3.786H6V27H31V3.786H24.75" transform="translate(56 615)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="MdiLightClipboardCheck" d="M23.809,14,13.294,22.938l-4.706-4,1.029-.888,3.676,3.125,9.471-8.063Z" transform="translate(58.301 613)" fill="currentColor" />
      </g>
    </svg>
  ),
  riskNotice: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22.999" viewBox="0 0 25 22.999">
      <path id="risk_notice_icon" data-name="risk notice icon" d="M1,25.809l12.5-23,12.5,23H1m22.94-1.264L13.5,5.339,3.06,24.545H23.94M12.9,16.959V11.9H14.1v5.058H12.9m0,2.529H14.1v2.529H12.9Z" transform="translate(-1 -2.81)" fill="currentColor" />
    </svg>
  ),
  arrowLeft: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18.774 39.952">
      <path id="arrow_icon" data-name="arrow icon" d="M0,0,19.345,17.567,38.54,0" transform="translate(18.067 0.706) rotate(90)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </svg>
  ),
  arrowRight: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18.774 39.952">
      <path id="arrow_icon" data-name="arrow icon" d="M0,17.567,19.345,0,38.54,17.567" transform="translate(18.274 0.706) rotate(90)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </svg>
  ),
};

export default function SideBarIcon(props) {
  if (props.icon in Icons) {
    return Icons[props.icon]();
  }
  return '';
}
